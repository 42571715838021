.navbar{
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    background-color: rgb(14, 11, 11);
    box-shadow: 0 2px 2px 2px rgba(20, 20, 20, 0.23);
    z-index: 1;
}

.logo-img{
    width: 50px;
    margin-left: 50%;
}

.navbar-menu{
    margin-left: auto;
}

.navbar-menu ul{

    display: flex;
    padding: 0;
}

.navbar-menu li{
    list-style-type: none;   
    margin: 0; 
}

.navbar-menu li a{
    text-decoration: none;
    display: block;
    width: 100%;
    color: white;
    padding: 1.3rem 1rem;
}

.navbar-menu li a:hover{
    background: red;
}

.login{
    margin-left: auto;
    margin-right: 3rem;
}

.login ul{
    display: flex;
    align-items: center;
    padding: 0;
}

.login li{
    margin: 0; 
    list-style: none;
}

.login li a{
    text-decoration: none;
    display: block;
    width: 100%;
    color: white;
}

.login li a:hover{
    background: red;
  }


.hamburger{
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #686363;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: none;
   
}

.hamburger:hover{
    background-color: rgb(60, 165, 235);
}

.myOrder_link{
    font-size: 1.5rem;
    padding: 0.96rem ;
}

.log_in{
  padding: 1.3rem 0.5rem;
}
.profile{
  padding: 0.69rem 0.5rem;
}

/* media queries */

@media screen and (max-width:800px){
    .hamburger{
        display: block;
    }

    .navbar-menu ul {  
         display: none; 
        position: absolute;
        top: 60px;
        left: 0;
        flex-direction: column;
        width: 100%;
        /* height: calc(100vh - 77px); */
        background-color: white;
        border-top: 1px solid black;
      }
      .navbar-menu li {
        
        text-align: center;
        margin: 0;
      }
      .navbar-menu li a {
        color: black;
        width: 100%;
        padding: 1.5rem 0;
      }
      .navbar-menu li:hover {
        background-color: #eee;
      }
      
      .login{
        display: flex;
      }

      .login ul{
        padding: 10px;
      }

      .login ul li{
        margin: 10px;
      }

      
      /* .login ul {  
        display: none;
        position: absolute;
        top: 60px;
        left: 0;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 77px);
        background-color: white;
        border-top: 1px solid black;
      }
      .login li {
        
        text-align: center;
        margin: 0;
      }
      .login li a {
        color: black;
        width: 100%;
        padding: 1.5rem 0;
      }
      .login li:hover {
        background-color: #eee;
      } */

     .navbar-menu.expanded ul{
        display: block;
     }

}