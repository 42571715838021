/*styling background*/
.mainLogin {
    background-image:linear-gradient(to bottom left, rgb(255, 255, 255), rgb(167, 164, 164));
    border: 1px solid white;
}

/*styling for login box*/
.containerLogin {
    margin: 2rem auto;
    width: 50%;
    height: 40rem;
    box-shadow: 3px 1px 7px 7px rgba(17, 17, 17, 0.22);
    background-color: rgb(245, 232, 56, 0.363);
}

/*styling login form*/
.headingLogin {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    padding-top: 1rem;
}
.loginImg {
    margin: 1rem auto;
    display: flex;
    justify-self: center;
    width: 5rem;
    height: 5rem;
}
.loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
}
.inputLogin {
    width: 15rem;
    margin: 1rem;
    position: relative;
    height: 2rem;
    padding-left: 5px;
    padding-top: 10px;
}
.inputLogin:focus {
    height: 2.2rem;
    padding: 10px;
}
.inputLogin:valid {
    height: 2.5rem;
    font-size: 16px;
}
.labelLogin {
    top: 22px;
    left: 20px;
    position: absolute;
    opacity: 0.2;
}
.inputLogin:focus ~ .labelLogin,
.inputLogin:valid ~ .labelLogin {
  position: absolute;
  transition: all 1s ease-in-out;
  background-color: rgb(231, 231, 231);
  opacity: 1;
  left: 20px;
  top: 7px;
}
.buttonLogin {
    height: 2rem;
    width: 5rem;
    border-radius: 20px;
    font-weight: bold;
}
.textLogin {
    text-align: center;
    margin-top: 5rem;
}



