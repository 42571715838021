.Dashboard_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.selection{
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 15%;
  margin: auto;
  text-align: center;
  font-size: 1.3rem;
  font:bold;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
  cursor: pointer;

  
}

.selection a{
  text-decoration: none;
  color: #000000;
  font-size: 1.5rem;
  font-weight: bold;

}

.selection:hover {
  background-color: #0b2a3c;
  color: #ffffff;

}

.selection:hover:after {
  border-color: #e1f0dd transparent transparent transparent;
}

.selection:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #e0eddc transparent transparent transparent;

}

.Admin_nav {
 all: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #000000;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
  cursor: pointer;
}

.Admin_nav_div {
  width: 100%;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;


}

.Admin_nav_ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;

}

.Admin_nav_li {
    padding: 0 20px;

    border-right: 1px solid #ffffff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.Admin_nav_li a {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: bold;
  width: 100%;
  padding: 1rem 1rem;
}

