.fav_dishes_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 50%;
  background-color: #f5f5f5;
  padding: 0 15px;
}

.heading_fav_dishes {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-family: "Roboto", sans-serif;
  padding-top: 25px;
}

.pictures_fav {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  flex-wrap: nowrap;
  padding: 25px; */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
}

.fav_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  padding: 0 20px;
}

.fav_pic {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  height: 200px;
  width: 200px;
  object-fit: cover;
}
.fav_pic:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
.fav_image_cont {
  width: 200px;
  height: 200px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  border-radius: 50%;
}
.fav_image_cont:hover {
  z-index: 999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.fav_menu {
  color: blue;
  width: 200px;
  height: 200px;
}
.fav_p {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1%;
}

@media screen and (max-width:1000px) {
    .pictures_fav{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin-bottom: 20px;
        flex-wrap: wrap;
        padding: 25px;
    }
    .fav_pic{
        height: 200px;	
        width: 200px;
        object-fit: cover;
        
    }
    .fav_image_cont{
        width: 200px;
        height: 200px;;
        overflow: hidden;
        position: relative;
        display: inline-block;
        border-radius: 50%;	
    
    
    }
    .fav_menu{
        color:blue;
        
        width: 200px;
        height: 200px;
       
    
    
    }
    .fav_p{
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 15px;
    }
    
}



.food_name {
  margin: 15px;
  color: #000;
  font-weight: 500;
}
