.dishes_container{
    background-image: url(../images/dishes.jpg); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;


}

.post-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 30%;
    background-color: rgba(15, 45, 92, 0.9);
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: bold;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 10px;
    text-align: center;
}

.dish-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 70%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 10px;
/*     background-color: rgba(239, 234, 204, 0.5); */
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: bold;
    
   
}

.dish-info input{
    height:2rem;
    width: 70%;
    margin: 1rem;
    border-radius: 5px;
    border: 1px solid #fbf6f6;
    background-color: white;
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;


}

.dish-info ::placeholder{
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    font-style: italic;
    font-size: 17px;
    text-align: center;
    color:  #0b2a3c;
   
}
   

.radio-container{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    height: 100%;
    width: 100%;
   
}

 .radio-container section{
    display: flex;
    flex-direction: column;
   
}
 .dish-info div section label{
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;


}

.dish-info  h4{
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    margin-top: 10px;
}

.dish-info div section input{
    height: 1.5rem;
    width: 100%;
    margin: 1rem;
    padding-left: 5px;
    padding-top: 10px;
    border-radius: 5px;
    border: 1px solid #fbf6f6;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ef2222;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;

}

.dish-info input{
    color: #0b2a3c;
}



.dish-info div section input[type="radio"]{
    height: 1rem;
    width: 1rem;
    margin: 1rem;
    padding-left: 5px;
    padding-top: 10px;
    border-radius: 5px;
    border: 1px solid #fbf6f6;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;

}

.first-form{
    width: 50%;
    display:flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.first-form input{
    height: 2.5rem;
    width: 40%;
    margin: 1rem;
    padding-left: 5px;
    padding-top: 10px;
    border-radius: 5px;
    border: 1px solid #0b2a3c;
    background-color: rgba(216, 200, 200, 0.5);
    color: #ef2222;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
}

.first-form ::placeholder{
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    font-style: italic;
    font-size: 17px;
    text-align: center;
    color:  #0b2a3c;
}


.buttonAddDish{
    height: 2rem;
    width: 7rem;
    margin: 1rem;
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #fbf6f6;
    background-color: white;
    color: #c29e0e;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

.buttonAddDish:hover{
    background-color: #c29e0e;
    color: white;
}

.delete-dishes-h1{
    text-align: center;
    background-color: red;
    color: white;
    width: 20%;
    height: 3rem;
    margin: auto;
    padding-top: 5px;
  
}
.update-dishes-h1{
    text-align: center;
    background-color:#0b2a3c;
    color: white;
    width: 20%;
    height: 3rem;
    margin: auto;
    padding-top: 5px;

}
.get-dishes{
   width: 100%;
    background-color: white;
    color: black;
    font-size: 1.5rem;
    margin: auto;
    text-align: center;
}
.get-dishes-all{

    width: 80%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 3fr)); 
    grid-gap: 70px;
  

}

.get-dishes-items{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 450px;
   /*  background-color: rgba(15, 45, 92, 0.9); */
    color:rgb(28, 5, 5);
    margin: auto;
    box-shadow: 10px 5px 5px  rgba(15, 45, 92, 0.9);
    
}

.get-dishes-items img{
    height: 250px;
    width: 100%;
    object-fit: cover;
}

.get-dishes-items h3{
    width: 100%;

    text-align: center;

}
.get-dishes-items span {
    font-weight: bold;
}

.get-dishes-items p:last-child{
    text-align: left;
}

.delete-vh-50{
    height: 50vh;
}

.update-page-view{
    margin-bottom:13%;
}

@media screen and (max-width: 768px){
    .get-dishes-all{
        grid-template-columns: repeat(auto-fit, minmax(300px, 3fr)); 
    }
    .get-dishes-items{
        width: 300px;
    }
    .get-dishes-items img{
        height: 200px;
    }
    .get-dishes-items h3{
        font-size: 1.5rem;
    }
    .get-dishes-items p{
        font-size: 1rem;
    }
    .get-dishes-items p:last-child{
        font-size: 1rem;
    }
    .get-dishes-items span{
        font-size: 1rem;
    }
    .get-dishes-items p:last-child{
        text-align: left;
    }

}

@media screen and (max-width: 480px){
    .get-dishes-all{
        grid-template-columns: repeat(auto-fit, minmax(200px, 3fr)); 
    }
    .get-dishes-items{
        width: 200px;
    }
    .get-dishes-items img{
        height: 150px;
    }
    .get-dishes-items h3{
        font-size: 1.5rem;
    }
    .get-dishes-items p{
        font-size: 1rem;
    }
    .get-dishes-items p:last-child{
        font-size: 1rem;
    }
    .get-dishes-items span{
        font-size: 1rem;
    }
    .get-dishes-items p:last-child{
        text-align: left;
    }

}

@media screen and (max-width: 320px){
    .get-dishes-all{
        grid-template-columns: repeat(auto-fit, minmax(150px, 3fr)); 
    }
    .get-dishes-items{
        width: 150px;
    }
    .get-dishes-items img{
        height: 100px;
    }
    .get-dishes-items h3{
        font-size: 1.5rem;
    }
    .get-dishes-items p{
        font-size: 1rem;
    }
    .get-dishes-items p:last-child{
        font-size: 1rem;
    }
    .get-dishes-items span{
        font-size: 1rem;
    }
    .get-dishes-items p:last-child{
        text-align: left;
    }

}





















