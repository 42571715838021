.mainServices {
    background-image:linear-gradient(to bottom left, rgb(255, 255, 255), rgb(167, 164, 164));
    border: 1px solid white;
}

.containerServ {
    width: 90%;
    height: fit-content;
    margin: 4rem auto;
    /* background-color: rgba(245, 232, 56, 0.363);
    border: 2px solid gray;
    border-radius: 30px; */
}

.headingServ {
    font-size: 40px;
    text-align: center;
    padding-top: 1rem;
    margin-bottom: -1rem;
}

.containerServices {
    display: flex; 
    margin: -1rem;
}

.reservation, .delivery {
    display: grid;
    margin: 3rem;
    /* background-image:linear-gradient(to bottom left, rgb(255, 255, 255), rgb(167, 164, 164)); */
    background-color: rgba(167, 164, 164, 0.479);
    border-radius: 30px;
    border: 2px solid gray;
    box-shadow: 3px 1px 7px 7px rgba(17, 17, 17, 0.22);
}

.img_Serv {
    width: 17rem;
    height: 10rem;
    padding-top: 1rem;
    justify-self: center;
}

.headingBox {
    padding-bottom: 1rem;
    text-align: center;
}

.textBox {
    padding: 1rem;    
}

/* tablet size*/
@media screen and (max-width: 1024px) {
    .containerServ {
        margin: 1rem;
    }

    .containerServices {
        display: inline-block;
        margin: 1rem;
    }

    .reservation, .delivery {
        margin: 1rem;
    }

    .img_Serv {
        border-radius: 30px;
    }
}

/*phone size*/
@media screen and (max-width: 450px) {
    .containerServ {
        margin: 0;
    }

    .headingServ {
        margin-bottom: -1rem;
    }

    .containerServices {
        width: 90%;
        display: inline-block;
        margin: 1rem;
    }

    .reservation, .delivery {
        margin: 0s;
    }

    .img_Serv {
        margin:0rem;
        border-radius: 25px;
        width: auto;
        max-width: 10rem;
    }
}

.buttonServ {
    margin-bottom: 1rem;
    border-radius: 20px ;
    width: 10rem;
    height: 30px;
    text-align: center;
    justify-self: center;
    background-color:black;
    color: white;
    font-weight: bold;

}

.buttonServ:hover {
    transform: scale(1.3);
    
}