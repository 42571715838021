.order-container, .shipment, .payment{
    width: 75%;
    height: 100%;
    background-color: aliceblue;
    border-radius: 10px;
    box-shadow: 3px 1px 7px 7px rgba(17, 17, 17, 0.22);
    margin: 5rem auto;
}

table{
    width: 100%;
}
.order-heading{
    text-align: center;
    padding-top: 3rem;
    
}
.table-head{
    background-color: #04AA6D;
}
.table-heading{
    background-color: #04AA6D;
    color: white;
    text-align: center;
}


.dish-img{
    width: 100%;
    height: 15vh;
    object-fit: cover;
}
  .pay-to{
    display: flex;
    justify-content: space-evenly;
    height: 5rem;
    margin-top: 2rem;
  }

  .menu-back{
    background-color: brown;
    font-size: 1.5rem;
    padding: 0.5rem;
    border-radius: 10px;
    height: 60%;
  }

  .payment-mode{
    background-color: brown;
    font-size: 1.5rem;
    padding: 0.5rem;
    border-radius: 10px;
    height: 60%;
  }


@media screen and (min-width:1000px){
.table-heading th {
    padding: 20px;
    background-color: #04AA6D;
    color: white;
    text-align: center;
  }
  .table-body tr td{
    padding: 30px;
  }
 

}
.cart-total{
   width:100%;
    color: brown;
    font-size: 1.5rem;
    text-align: right;
  }
  @media screen and (max-width:700px){
    .pay-to{
    display:flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    height: 5rem;
}
.menu-back{
    background-color: rgb(12, 211, 246);
    font-size: 1.2rem;
    padding: 0.2rem;
    border-radius: 10px;
    height: 40%;
  }

  .payment-mode{
    background-color: rgb(12, 211, 246);
    font-size: 1.2rem;
    padding: 0.2rem;
    border-radius: 10px;
    height: 40%;
  }
  }

 .shipping-header{
  display: grid;
  place-items: center;
 }


 .svg-order{
  width:100%;
  max-width: 600px;
 }
 
 .info-data{
  display: flex;
 justify-content: center;
 align-items: center; 
 padding-top: 15px; 
  height: 100%;
  padding: 20px;
  width: 65%;
  margin: auto;
 }

 .info-data h2{
  text-align: center;
  padding: 15px;
 }
 
  .order-form {
  width: 100%;
 
  padding:3rem;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 0 20px 0 #095484; 
  }
 .form-item {
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px; 
  width: calc(100% - 10px);
  padding: 0.5rem;
  height: 20%;
  }
 
  .name-item, .city-item{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 20%;
  }
  .privacy{
    color: rgb(0, 136, 255);
  }

  .form-item-name{
    width:45%;
    border: 1px solid #ccc;
    border-radius: 3px; 
    height:2rem;
    margin-bottom: 1rem;
  }


  .item p{
    margin-left: 20px;
    margin-bottom: 10px;
  }
  .item:hover p, .item:hover i, .question:hover p, .question label:hover, input:hover::placeholder{
  color: #095484;
  } 
  .item input:hover, .item select:hover {
  border: 1px solid transparent;
  box-shadow: 0 0 6px 0 #095484;
  color: #095484;
  }
  .item {
  /* position: relative; */
  margin: 10px 0;
  }
 
    label.check {
      position: relative;
      display: inline-block;
      margin: 5px 20px 10px 0;
      cursor: pointer;
      }
      .question p{
      margin-left: 1.2rem;
      margin-bottom: 5px;
      }
      span.required {
      margin-left: 0;
      color: red;
      }

    .checkbox-item{
     display: flex;
      width: 80%;
      align-items: center;
    }  

    .checkbox-item p{
      width: 80%;
    }
  @media (max-width: 868px) {
    .name-item, .city-item {
    display: flex;
    flex-wrap: wrap;
   
    }
   
   
    .info-data{
      width:100%;
    }

    .name-item{
      width: 100%;
    }
    .form-item-name{
      width: 100%;
      padding: 0;
    
    }
    }


.shipment{
  display: flex;
  justify-content: center;
  align-items: center; 
   height: inherit;
   
}
  .shipment label, .payment label{
    all:unset;
  }

.shipment h2, .payment h2{
  text-align: center;
  padding: 2rem;
  font-size: 2rem;
}

.shipment{
  display: flex;
  flex-direction: column; 
}

.shipment  label{
    /* width: 180px; */
    box-shadow: var(--shadow-big);
    padding: var(--space-medium);
    margin-bottom: var(--space-small);
    display: flex;
    justify-items: center;
    align-content: center;
  }
.payment-img, .liefer-img, .bar-img{
  
  height: 10rem;
  border-radius: 1rem;
 
}


.shipment-mode{
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.pickUp {
  box-sizing: border-box;
  height: 8.5rem;
  box-shadow: 3px 1px 7px 7px rgba(17, 17, 17, 0.22);
  border-radius: 1rem;
  text-align: center;
  display: flex;
  padding: 0 1rem;
  align-items: center;
  font-size: 2rem;
  font-weight: bolder;
}

.pickUp-radio:checked ~ .pickUp{
  background-color: blue;
  color: white;
}

.payment-mode2{
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.paypal{
  width: 75%;
  height: 10rem;
  color: rgb(47, 46, 46);
  justify-content: space-around;
  align-items: center;
}

.shipping-info input{
  display: none;
}

.visa_svg:checked ~ .paypal{
  color:blue
}

.bar_img:checked ~ .bar-img{
  fill: rgb(76, 114, 18);

}

.liefer-img{
  fill: #000000;

}

.liefer_img:checked ~ .liefer-img{
  fill: rgb(195, 175, 26);
}
@media screen and (max-width:800px){
  .shipment-mode, .payment-mode2{
    display:flex;
    flex-direction: column;
    align-items: center;
  }

 .liefer-img, .pickUp{
  margin-bottom: 1.5rem;
 }
 
}

.order-done{
  font-size: 4rem ;
   text-align: center;
   padding: 10rem;
}

.order-details{
   width: 30%;
    height: 100%;
    background-color: aliceblue;
    border-radius: 10px;
    box-shadow: 3px 1px 7px 7px rgba(17, 17, 17, 0.22);
    padding: 2rem;
    margin: auto;
}

.detail-bill td{
  padding-left: 2rem;
  padding-top: 0.5rem;
}
.total-final{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
}

.total-final p{
  padding-left: 1rem;
}

.bill-details{
margin: 5% 0;
}

.last-button{
  display: flex;
  justify-content: center;
  margin-top: 5%;
}


@media screen and (max-width:1000px){
  .order-details{
    width: 80%;
  }
}
