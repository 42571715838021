.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.carousel {
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  position: relative;
  height: 70vh;
}

.carousel-item {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.carousel-inner {
  white-space: nowrap;
  transition: ease 3s;
  height: 100%;
  width: 100%;
}

.carousel-control {
  background-color: rgb(0, 0, 0, 0.5);
  border: none;
  display: inline-block;
  position: absolute;
  height: 50px;
  width: 70px;
  top: auto;
  color: #ffffff;
  cursor: pointer;
}

.left {
  left: 0;
}

.right {
  right: 0;
}
