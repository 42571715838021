.container_offer {
    width: 80%;
    height: fit-content;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.heading_offer {
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.offer{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 25px;
    width: 100%;
}

.cont_offer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 1rem;
}

.sectionOffer {
    width: 15rem;
    height: 20rem;
    text-align: center;
}

.figureOffer {
    width: 15rem;
    height: 20rem;
    box-shadow: 3px 1px 7px 7px rgba(17, 17, 17, 0.22);
}

.food_img {
    width: 150px;
    height: 150px;
    border: 2px solid black;
    border-radius: 50%;
    margin: auto;
}

.new_sticker {
    width: 40px;
    height: 20px;
    margin-right: -3rem;
    background-color: greenyellow;
    color: white;
    text-align: center;
    font-weight: 600;
}

.nameOffer {
    font-weight: bold;
    padding-top: 20px;
}

.priceOffer {
    color: rgb(152, 247, 10);
}

.descriptionOffer {
    color: rgba(0, 0, 0, 0.555);
}

/*styling for different window sizes (distance between heading offer and yellow box from booking*/

/*minimize screen until tablet size*/
@media screen and (min-width: 1384px) and (max-width: 1540px){
    .heading_offer {
        margin-top: 1rem;
    }
    .container_offer {
        width: 100%;
        margin-top: 6rem;
    }
}
@media screen and (min-width: 870px) and (max-width:1383px){
    .heading_offer {
        margin-top: 1rem;
    }
    .container_offer {
        width: 100%;
        margin-top: 8rem;
    }
    .offer{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 25px;
        width: 100%;
    }
}

/*tablet size*/
@media screen and (min-width: 712px) and (max-width: 870px) {
    
    .container_offer {
        width: 100%;
        margin-top: 10rem;
    }
    .offer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .heading_offer {
        margin-top: 1rem;
        margin-bottom: 0;
    }
}


/*phone size until tablet size*/
@media screen and (max-width: 711px) {
    .container_offer {
        width: 100%;
        margin-top: 15rem;
    }
    .offer {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .heading_offer {
        margin-top: 4rem;
        margin-bottom: 0;
    }
}

