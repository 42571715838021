
h4 {
    letter-spacing:  0.1rem;
    text-transform: capitalize;
    line-height: 1.25;
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
  }
  
  h3 {
    font-size: 1.25rem;
  }
  
  
  p.menu {
    margin-bottom: 1.25rem;
    color: rgb(82, 121, 165);
  }
  
  .section {
    width: 90vw;
    margin: 0 auto;
    max-width: 1170px;
    display: block;
    width: 95vw;
  }
  
  .menu {
    padding: 5rem 0;
  }
  .title {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .underline {
    width: 6rem;
    height: 0.25rem;
    background: #5e4f3a;
    margin-left: auto;
    margin-right: auto;
  }
  
  .btn-container {
    margin-bottom: 4rem;
    display: flex;
    justify-content: center;
  }

  .filter-btn:active{
    color: green;
    
  }
  
  .filter-btn {
    background: transparent;
    border-color: transparent;
    font-size: 1.5rem;
    text-transform: capitalize;
    margin: 0 0.5rem;
    letter-spacing: 1px;
    padding: 0.375rem 0.75rem;
    color:  rgb(240, 10, 10);
    cursor: pointer;
    transition: all 0.3s linear;
    border-radius: 0.25rem;
  }
  
  .active {
    color: rgb(250, 253, 250);
    background-color: rgb(255, 0, 0);
  }
  
  .section-center {
    width: 90vw;
    margin: 0 auto;
    max-width: 1170px;
    display: grid;
    gap: 3rem 2rem;
    justify-items: center;
    cursor: pointer;
  }
  
  .menu-item {
    display: grid;
    gap: 1rem 2rem;
    width: 20rem;
    height: 20rem;
    /* border: 0.25rem solid rgb(14, 13, 13); */
    /* background-color: rgb(238, 235, 235); */
    box-shadow: 3px 1px 7px 7px rgba(17, 17, 17, 0.22);
    transition: transform .5s;
  }
  
  .menu-item:hover{
    transform: scale(1.1);
  }

  .photo {
    object-fit: cover;
    height: 200px;
    width: 100%;
  }

  .logo {
    height: 100px;
    width: 100px;
    padding: 1rem;
  }
  .con_logo{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: black;
    margin: 1rem auto;
  }
  
  
  .item-info header {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px dotted rgb(16, 17, 17);
  }
  .item-info h4 {
    margin-bottom: 0.5rem;
    padding: 0 1.5rem;
  }

  .item-info p{
    padding: 0.5rem 1.5rem;
  }

  .price {
    color: #99210c;
    white-space: nowrap;
  }

  .desc {
    margin-bottom: 0;
    padding-top: 1rem;
    color: rgb(16, 17, 17);
  }
  
  .menu-item:active{
    box-shadow: 3px 1px 7px 7px rgba(8, 102, 254, 0.22);
    
  }

  @media screen and (max-width: 500px) {
    .menu-item {
      /* grid-template-columns: 225px 1fr;
      gap: 0 1.25rem; */
      display: flex;
      flex-direction: column;
      max-width: 40rem;
    }
    .btn-container{
      display: grid;
    }
    .photo {
      height: 175px;
    }
  }

  @media screen and (min-width:770px) {
    .section-center {
      width: 95vw;
      grid-template-columns: 1fr 1fr;
    }
    .photo {
      height: 200px;
    }
    
  }
  @media screen and (min-width: 1170px) {
    .section-center {
      width: 95vw;
      grid-template-columns: 1fr 1fr 1fr;
    }
    .photo {
      height: 200px;
    }
  }

  .menu-container{
    background-image:linear-gradient(to bottom left, rgb(255, 255, 255), rgb(167, 164, 164))
  }

