*{box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/*floating label above input*/
/*!!! commented code: please define this inside css for your page with a class !!!*/
.floating_group /*div container for input and label inside form*/ {
position: relative;
}

/*input {
  position: relative;
  height: 2rem;
  margin: 1rem;
  padding-left: 5px;
  padding-top: 10px;

}

label {
  position: absolute;
  opacity: 0.2;

}

input:focus {
  height: 2.2rem;
  padding: 10px;
}


input:focus ~ label,
input:valid ~ label
 {
  position: absolute;
  transition: all 1s ease-in-out;
  background-color: rgb(231, 231, 231);
  opacity: 1;

}

textarea:focus ~ label,
textarea:valid ~ label {
  position: absolute;
  transition: all 1s ease-in-out;
  background-color: rgb(231, 231, 231);
  opacity: 1;

}


input:valid {
  height: 2.5rem;
  font-size: 16px;
}

textarea:valid {
  padding-top: 15px;
}

 */


